import React from "react";

import useWindow from "../hooks/useWindow";

import { Box } from "@mui/material";

const PageHeader = () => {
  const { windowWidth } = useWindow();

  return (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: "50px",
        backgroundColor: "#006800",
      }}
    ></Box>
  );
};

export default PageHeader;
