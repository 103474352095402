import React from "react";

import useWindow from "../hooks/useWindow";

import { Box, Typography } from "@mui/material";

import Logo from "../assets/desktop/DFL_Logo_Rerversed.png";
import Buttons from "../assets/desktop/Buttons_InfinitySymbol.png";
import Header from "../assets/desktop/DesktopHeader.png";
import Copyline from "../assets/desktop/TheFirstOfManyCopyline.png";
import OFDLogo from "../assets/desktop/OFD_hrzntl_logo_All_White.svg";

const PageBody = () => {
  const { windowHeight, iOS, isMobile } = useWindow();

  const handleVisitOFD = () => {
    let url = "https://www.ourfamilydashboard.com";

    if (iOS) {
      window.location.replace(url);
    } else if (isMobile) {
      document.location(url);
    } else {
      window.location.replace(url);
    }
  };

  return (
    <Box
      sx={{
        paddingLeft: "15%",
        paddingRight: "15%",
        paddingTop: "50px",
        height: `${windowHeight - 50}px`,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <Box sx={{ marginTop: "50px" }}>
            <img src={Logo} />
          </Box>
          <Box sx={{ paddingTop: "3rem" }}>
            <img
              src={Header}
              style={{ height: `${windowHeight / 3}px` }}
            />
          </Box>
          <Box
            sx={{
              marginTop: "2rem",
              borderBottom: "1px solid #ccc",
              marginBottom: "1rem",
            }}
          >
            <img src={Copyline} />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <img
                  src={OFDLogo}
                  style={{ height: "3rem" }}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    padding: ".25rem",
                    borderRadius: "10px",
                    cursor: "pointer",
                  }}
                  onClick={handleVisitOFD}
                >
                  <Typography sx={{ color: "#ccc" }}>
                    {"> Visit Site"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <img
            src={Buttons}
            style={{
              height: `${windowHeight - 100}px`,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PageBody;
