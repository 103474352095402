import useWindow from "./hooks/useWindow";

import { Box } from "@mui/material";

import PageHeader from "./components/PageHeader";
import PageBody from "./components/PageBody";

function App() {
  const { isMobile, windowWidth, windowHeight } = useWindow();

  return (
    <Box
      sx={{
        width: `${windowWidth}px`,
        height: `${windowHeight}px`,
        backgroundColor: "#000000",
      }}
    >
      <PageHeader />
      <PageBody />
    </Box>
  );
}

export default App;
